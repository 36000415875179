const swup = new Swup({
    containers: ['#swup', '.uk-navbar-nav', '.uk-mobile-navigation'],
    animateHistoryBrowsing: true,
    plugins: [
        new SwupScrollPlugin({
            animateScroll: false
        }),
        new SwupSlideTheme({
            reversed: true
        }),
        new SwupScriptsPlugin({
            head: true
        })
    ]
});

window.addEventListener('load', init);
swup.on('pageView', init);


function init() {

    // burger menu effect
    var theToggle = document.getElementById("toggle");

    // based on Todd Motto functions
    // https://toddmotto.com/labs/reusable-js/

    // hasClass
    function hasClass(elem, className) {
        return new RegExp(" " + className + " ").test(" " + elem.className + " ");
    }
    // addClass
    function addClass(elem, className) {
        if (!hasClass(elem, className)) {
            elem.className += " " + className;
        }
    }
    // removeClass
    function removeClass(elem, className) {
        var newClass = " " + elem.className.replace(/[\t\r\n]/g, " ") + " ";
        if (hasClass(elem, className)) {
            while (newClass.indexOf(" " + className + " ") >= 0) {
                newClass = newClass.replace(" " + className + " ", " ");
            }
            elem.className = newClass.replace(/^\s+|\s+$/g, "");
        }
    }
    // toggleClass
    function toggleClass(elem, className) {
        var newClass = " " + elem.className.replace(/[\t\r\n]/g, " ") + " ";
        if (hasClass(elem, className)) {
            while (newClass.indexOf(" " + className + " ") >= 0) {
                newClass = newClass.replace(" " + className + " ", " ");
            }
            elem.className = newClass.replace(/^\s+|\s+$/g, "");
        } else {
            elem.className += " " + className;
        }
    }

    theToggle.onclick = function () {
        toggleClass(this, "on");
        return false;
    };

    //scrolltotop
    // Der Button wird mit JavaScript erzeugt und vor dem Ende des body eingebunden.
    var back_to_top_button = ['<a href="#top" class="back-to-top"><span uk-icon="icon: chevron-up; ratio: 2"></span></a>'].join("");
    $("body").append(back_to_top_button)

    // Der Button wird ausgeblendet
    $(".back-to-top").hide();

    // Funktion für das Scroll-Verhalten
    $(function () {
        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) { // Wenn 100 Pixel gescrolled wurde
                $('.back-to-top').fadeIn();
            } else {
                $('.back-to-top').fadeOut();
            }
        });

        $('.back-to-top').click(function () { // Klick auf den Button
            $('body,html').animate({
                scrollTop: 0
            }, 800);
            return false;
        });
    });

    //Product Detail Thumbnav
    $('.product-detail-thumbnails li').click(function(e) {
        e.preventDefault();
        $(this).siblings('.uk--active').removeClass('uk--active');
        $(this).addClass('uk--active');
        UIkit.slideshow('.product-detail-images.uk-slideshow').show($(this).data('target'));
    });


    /*Countdown*/
    function countDownTimer(date) {
        var elem = $('#countDown');

        //$( "p" ).add( "div" ).addClass( "widget" );
        var futureTime = new Date(date).getTime();

        setInterval(function() {
            // Time left between future and current time in Seconds
            var timeLeft = Math.floor( (futureTime - new Date().getTime()) / 1000 );
            // console.log(timeLeft);

            // Days left = time left / Seconds per Day
            var days =  Math.floor(timeLeft / 86400);
            // console.log(days);

            // 86400 seconds per Day
            timeLeft -= days * 86400;
            // console.log(timeLeft);

            // Hours left = time left / Seconds per Hour
            var hours = Math.floor(timeLeft / 3600) % 24;
            // console.log(hours);

            // 3600 seconds per Hour
            timeLeft -= hours * 3600;
            // console.log(timeLeft);

            // Minutes left = time left / Minutes per Hour
            var min = Math.floor(timeLeft / 60) % 60;
            // console.log(min);

            // 60 seconds per minute
            timeLeft -= min * 60;
            // console.log(timeLeft);

            // Seconds Left
            var sec = timeLeft % 60;

            // Combined DAYS+HOURS+MIN+SEC
            var timeString = "<div class='days'><span class='timer'>"+days+"</span><span>Tage</span>"+"</div>"+
                "<div class='hours'><span class='timer'>"+hours+"</span><span>Stunden</span>"+"</div>"+
                "<div class='minutes'><span class='timer'>"+min+"</span><span>Minuten</span>"+"</div>"+
                "<div class='seconds'><span class='timer'>"+sec+"</span><span>Sekunden</span>"+"</div>";

            elem.html(timeString);

        }, 1000);
    }

    // Enter date in this format: January 1, 2017 12:00:00
    countDownTimer('September 08, 2022 19:30:00');
}

/*Wave*/
const wave1 = "M0 108.306L50 114.323C100 120.34 200 132.374 300 168.476C400 204.578 500 264.749 600 246.698C700 228.647 800 132.374 900 108.306C1000 84.2382 1100 132.374 1150 156.442L1200 180.51V0H1150C1100 0 1000 0 900 0C800 0 700 0 600 0C500 0 400 0 300 0C200 0 100 0 50 0H0V108.306Z",
    wave2 = "M0 250L50 244.048C100 238.095 200 226.19 300 226.19C400 226.19 500 238.095 600 232.143C700 226.19 800 202.381 900 196.429C1000 190.476 1100 202.381 1150 208.333L1200 214.286V0H1150C1100 0 1000 0 900 0C800 0 700 0 600 0C500 0 400 0 300 0C200 0 100 0 50 0H0V250Z",
    wave3 = "M0 250L50 238.095C100 226.19 200 202.381 300 166.667C400 130.952 500 83.3333 600 101.19C700 119.048 800 202.381 900 214.286C1000 226.19 1100 166.667 1150 136.905L1200 107.143V0H1150C1100 0 1000 0 900 0C800 0 700 0 600 0C500 0 400 0 300 0C200 0 100 0 50 0H0V250Z",
    wave4 = "M0 125L50 111.111C100 97.2222 200 69.4444 300 97.2222C400 125 500 208.333 600 236.111C700 263.889 800 236.111 900 229.167C1000 222.222 1100 236.111 1150 243.056L1200 250V0H1150C1100 0 1000 0 900 0C800 0 700 0 600 0C500 0 400 0 300 0C200 0 100 0 50 0H0V125Z";

anime({
    targets: '.wave-top > path',
    easing: 'linear',
    duration: 7500,
    loop: true,
    d: [
        { value: [wave1, wave2] },
        { value: wave3 },
        { value: wave4 },
        { value: wave1 },
    ],
});

/*Ticker*/

var $tickerWrapper = $(".tickerwrapper");
var $list = $tickerWrapper.find("ul.list");
var $clonedList = $list.clone();
var listWidth = 10;

$list.find("li").each(function (i) {
    listWidth += $(this, i).outerWidth(true);
});

var endPos = $tickerWrapper.width() - listWidth;

$list.add($clonedList).css({
    "width" : listWidth + "px"
});

$clonedList.addClass("cloned").appendTo($tickerWrapper);

//TimelineMax
var infinite = new TimelineMax({repeat: -1, paused: true});
var time = 60;

infinite
    .fromTo($list, time, {rotation:0.01,x:0}, {force3D:true, x: -listWidth, ease: Linear.easeNone}, 0)
    .fromTo($clonedList, time, {rotation:0.01, x:listWidth}, {force3D:true, x:0, ease: Linear.easeNone}, 0)
    .set($list, {force3D:true, rotation:0.01, x: listWidth})
    .to($clonedList, time, {force3D:true, rotation:0.01, x: -listWidth, ease: Linear.easeNone}, time)
    .to($list, time, {force3D:true, rotation:0.01, x: 0, ease: Linear.easeNone}, time)
    .progress(1).progress(0)
    .play();

//Pause/Play
$tickerWrapper.on("mouseenter", function(){
    infinite.pause();
}).on("mouseleave", function(){
    infinite.play();
});
